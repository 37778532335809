import {
  Customer,
  formatPhoneNumber,
  getDisplayName,
  MutableCustomer,
  phoneNumbers,
} from "shared";
import { useHouseholdPolicies } from "./hooks/useHouseholdPolicies";
import { Paper } from "@hagerty/react-components";
import { AccountSettingsRow } from "./AccountSettingsRow/AccountSettingsRow";
import { logSettingsDialog } from "./utils/logSettingsDialog";
import { SourceType, Status } from "./SettingsDialog/SettingsDialog.types";
import {
  useIsSso,
  useIsStateFarmPartitioned,
} from "../../app/Providers/AppContext";
import { useState } from "react";
import { useDialog } from "../Dialog";
import { mt } from "../../utils/tracking";
import { StateFarmHelpDialog } from "./StateFarmHelpDialog/StateFarmHelpDialog";
import { SettingsDialog } from "./SettingsDialog/SettingsDialog";
import { Alert } from "../Alert";
import { Link } from "../Link";
import { useBeforeunload } from "react-beforeunload";

export type SettingsProps = {
  initialCustomer: Customer;
  isPolicyHolder?: boolean;
};

export const SettingsWrapped = ({ initialCustomer }: SettingsProps) => {
  const policies = useHouseholdPolicies();

  // If policy status is unknown, play it safe by assuming policy holder
  const isPolicyHolder = policies === undefined ? true : !!policies.length;

  return (
    <div id="account-settings" className="container my-4 my-md-6 m-auto">
        <Settings
          initialCustomer={initialCustomer}
          isPolicyHolder={isPolicyHolder}
        />
    </div>
  );
};

const Settings = ({ initialCustomer, isPolicyHolder }: SettingsProps) => {
  const [dialogStatus, setDialogStatus] = useState<Status>("ok");
  const [customer, setCustomer] = useState<Customer>(initialCustomer);

  const displayName = getDisplayName(initialCustomer);
  const { line1, line2, city, state, postalCode, phone } = customer;

  const editDialog = useDialog();
  const stateFarmDialog = useDialog();

  // Notify the user of unsaved changes if they close the window before the save is complete
  useBeforeunload((event: any) => {
    if (dialogStatus === "pending") {
      event.preventDefault();
      return "Save in progress";
    }
  });

  const formatAddress = line2
    ? `${line1} ${line2}, ${city}, ${state} ${postalCode}`
    : `${line1}, ${city}, ${state} ${postalCode}`;
  const address = line1 ? formatAddress : "Not provided";

  const [sourceType, setSourceType] = useState<SourceType | null>(null);

  const isSso = useIsSso();
  const hasStateFarmPartition = useIsStateFarmPartitioned();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isStateFarmSsoLogin = hasStateFarmPartition && isSso;

  const showHelpDialogOnEdit = hasStateFarmPartition;

  const openEditDialog = (type: SourceType) => {
    logSettingsDialog(type);
    setSourceType(type);
    editDialog.open();
  };

  const closeEditDialog = () => {
    mt.trackPopupClose();
    editDialog.close();
  };

  const openStateFarmDialog = () => {
    mt.trackPopup("state farm");
    stateFarmDialog.open();
  };

  const closeStateFarmDialog = () => {
    mt.trackPopupClose();
    stateFarmDialog.close();
  };

  // The mutate is slow (around 5 seconds). So, do an optimistic update to improve perceived performance
  async function onSubmit(mutableCustomer: MutableCustomer) {
    setDialogStatus("pending");

    // Copy current customer record before optimistically updating in case we need to roll back when the save fails
    const oldCustomer = customer;

    // Since mutable customer only has some of the necessary properties, blend with existing full customer
    const newCustomer = { ...customer, ...mutableCustomer };

    // Optimistically update the app's UI to show the new record, even though it hasn't been saved yet (to avoid drawing attention to the slow call)
    setCustomer(newCustomer);

    // Close the dialog immediately when the form is submitted.
    // This avoids "locking" the user into the modal while the save is in progress.
    // We have comprehensive client-side validation, so we can be confident the save will
    // succeed if client-side validation passes.
    closeEditDialog();

    try {
      const resp = await fetch(`${window.location.origin}/api/customer`, {
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(mutableCustomer),
      });
      if (!resp.ok) throw resp;
      // Although the dialog was already hidden, setting this status is important in case someone reopens the dialog.
      setDialogStatus("ok");
      return true;
    } catch {
      // If the save fails, show the old data again...
      setCustomer(oldCustomer);

      // And display an error message
      setDialogStatus("mutateError");
      return false;
    }
  }

  const handleEditClick = (type: SourceType) => {
    showHelpDialogOnEdit ? openStateFarmDialog() : openEditDialog(type);
  };

  // TODO: update following functions to forward to proper urls

  const handleEmailClick = () => {
    null;
  };

  const handleChangePasswordClick = () => {
    null;
  };

  const handleManageClick = () => {
    null;
  };

  return (
    <Paper theme="light" className={"w-100 m-auto"}>
      <h2 className="mb-4">Your account settings</h2>
      <div className="pt-2 pb-2">
        <h4>Login</h4>
        <AccountSettingsRow
          title={"Email"}
          value={initialCustomer.email}
          buttonTitle={"Edit"}
          hasDivider={true}
          onClick={() => handleEmailClick}
          buttonLabel={"Edit email"}
        />
        {/* TODO: add isStateFarmSsoLogin check to allow change password */}
        <AccountSettingsRow
          value={"Password"}
          buttonTitle={"Change password"}
          hasDivider={true}
          onClick={() => handleChangePasswordClick}
          buttonLabel={""}
        />
      </div>
      <div className="pt-2 pb-2">
        <h4>User details</h4>
        <AccountSettingsRow
          title={"Name"}
          value={displayName}
          buttonTitle={"Edit"}
          hasDivider={true}
          onClick={() => handleEditClick("mailing address")}
          buttonLabel={"Edit name"}
        />
        <AccountSettingsRow
          title={"Mailing address"}
          value={address}
          buttonTitle={"Edit"}
          hasDivider={true}
          onClick={() => handleEditClick("mailing address")}
          buttonLabel={"Edit mailing address"}
        />
        <AccountSettingsRow
          title={"Phone number"}
          value={phone ? formatPhoneNumber(phone) : "Not provided"}
          buttonTitle={"Edit"}
          hasDivider={true}
          onClick={() => handleEditClick("phone number")}
          buttonLabel={"Edit phone number"}
        />
      </div>
      <div className="pt-2 pb-2">
        <h4>Communication preferences</h4>
        <AccountSettingsRow
          value={"Email subscriptions"}
          buttonTitle={"Manage"}
          hasDivider={true}
          onClick={() => handleManageClick}
          buttonLabel={"Manage email subscriptions"}
        />
        <AccountSettingsRow
          value={"Marketplace notifications"}
          buttonTitle={"Manage"}
          hasDivider={true}
          onClick={() => handleManageClick}
          buttonLabel={"Manage marketplace notifications"}
        />
        <AccountSettingsRow
          value={"Valuation notifications"}
          buttonTitle={"Manage"}
          onClick={() => handleManageClick()}
          buttonLabel={"Manage valuation notifications"}
        />
      </div>
      {editDialog.isOpen && sourceType && (
        <SettingsDialog
          isPolicyHolder={!!isPolicyHolder}
          sourceType={sourceType}
          status={dialogStatus}
          customer={customer}
          onSubmit={onSubmit}
          onCloseDialog={closeEditDialog}
        />
      )}
      {stateFarmDialog.isOpen && (
        <StateFarmHelpDialog onDismiss={closeStateFarmDialog} />
      )}

      {dialogStatus === "pending" && (
        <Alert
          ariaLabel="Saving contact information"
          message="Saving..."
          level="info"
        />
      )}

      {dialogStatus === "mutateError" && (
        <Alert
          onClose={() => setDialogStatus("ok")}
          closeButtonAriaLabel="Close error message"
          showIcon
          message={
            <>
              Sorry, we are unable to update your information at this time.
              Please try again or call <Link phoneNumber={phoneNumbers.auto} />{" "}
              for assistance.
            </>
          }
          level="danger"
        />
      )}
    </Paper>
  );
};
