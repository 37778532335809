import { ListCard } from "@hagerty/self-service-components";
import styles from "./SettingsSkeleton.module.scss";
import { Paper } from "@hagerty/react-components";

const ListCardSingleRowSkeleton = () => (
  <ListCard.Row>
    <div className={styles.sectionDetailRow}>
      <div className={styles.skeleton} />
      <div className={styles.skeleton} />
    </div>
  </ListCard.Row>
);

const ListCardDoubleRowSkeleton = () => (
  <ListCard.Row>
    <div className={styles.skeleton} />
    <div className={styles.sectionDetailRow}>
      <div className={styles.skeleton} />
      <div className={styles.skeleton} />
    </div>
  </ListCard.Row>
);

const ListCardHeaderSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.skeletonHeader} />
  </div>
);

const ListCardTitleSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.skeletonTitle} />
  </div>
);

export function SettingsSkeleton() {
  return (
    <div id="account-settings" className="container my-6 m-auto">
      <Paper theme="light" className={"w-100 m-auto"}>
        <ListCardHeaderSkeleton />
        <ListCardTitleSkeleton />
        <ListCardDoubleRowSkeleton />
        <ListCardDoubleRowSkeleton />
        <ListCardTitleSkeleton />
        <ListCardDoubleRowSkeleton />
        <ListCardDoubleRowSkeleton />
        <ListCardDoubleRowSkeleton />
        <ListCardTitleSkeleton />
        <ListCardSingleRowSkeleton />
        <ListCardSingleRowSkeleton />
        <ListCardSingleRowSkeleton />
      </Paper>
    </div>
  );
}
