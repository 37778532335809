import { cloneElement } from "react";

export type FormElementProps<IdType> = {
  id: IdType;
  label: string;
  required?: boolean;
  error?: string;
  children: React.ReactElement;
};

// Accepts an IDType to strongly type the ID
export function FormElement<IdType extends string>({
  id,
  label,
  error,
  required = false,
  children,
}: FormElementProps<IdType>) {
  const errorDivId = `${id}-error`;
  const hasError = Boolean(error);
  // Add id and aria-required props to children
  const enhancedChild = cloneElement(children, {
    id,
    // Deliberately using aria-required instead of required because we don't want
    // the browser's native required validation to fire. This way, validation is handled
    // in a consistent manner.
    "aria-required": `${required}`,
    "aria-invalid": `${hasError}`,
    "aria-errormessage": errorDivId,
  });
  return (
    <div
      className={`form-element form-element_small${
        hasError ? " has-error" : ""
      }`}
    >
      <label className="form-element__label" htmlFor={id}>
        {required && (
          <span title="Required" className="required">
            *{" "}
          </span>
        )}
        {label}
      </label>
      {enhancedChild}
      {error && (
        <div role="alert" id={errorDivId} className="form-element__help">
          {error}
        </div>
      )}
    </div>
  );
}
