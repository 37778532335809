import { Country } from "@hagerty/self-service-components/api/types";
import { getHdc1Dot0Benefits } from "./hdc1Dot0Benefits";
import { getHdc1Dot5Benefits } from "./hdc1Dot5Benefits";
import { HdcMembership } from "./types/HdcMembership.types";

export const getHdc1Dot0Country = (country: Country) =>
  country === "USA" ? "US" : "CAN";

export function getHdcBenefits(hdcMembership: HdcMembership, country: Country) {
  if (!hdcMembership) return null;
  const [version, level] = hdcMembership.split("-");
  if (level === "AAA") return null;
  return version === "1.0"
    ? getHdc1Dot0Benefits(`${getHdc1Dot0Country(country)}MEMB${level}`)
    : getHdc1Dot5Benefits(parseInt(level.replace("VIP", "4")), country);
}