import withSuspense from "../../hocs/withSuspense";
import { SettingsProps, SettingsWrapped } from "./Settings";
import { SettingsSkeleton } from "./SettingsSkeleton/SettingsSkeleton";
import { SettingsError } from "./SettingsError/SettingsError";

export const SettingsWithSuspense = withSuspense<SettingsProps>( 
  SettingsWrapped,
  {
    FallbackComponent: SettingsError,
    loading: <SettingsSkeleton />,
  }
);
