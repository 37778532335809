import * as Sentry from "@sentry/react";
import { ActivePolicyMetadata } from "shared";
import { useFetchApi } from "../../../hooks/useFetchApi";

export const useHouseholdPolicies = () => {
  try {
    return useFetchApi<ActivePolicyMetadata[]>("household/policies").data;
  } catch (error) {
    // This is needed due to how npm package fetch-suspense works.
    if (error instanceof Promise) {
      throw error;
    }

    Sentry.captureException(error, {
      contexts: {
        context: {
          message: "Unable to fetch household policies on account settings",
        },
      },
    });

    return undefined;
  }
};
