import { Country } from "@hagerty/self-service-components/api/types";
import { Environment } from ".";
import { TLD } from "./types/TLD.types";
import { onPremMap, URLs } from "./types/urlUtils.types";

/** Returns the URL origin based on the provided environment */
const getHagertyOriginSansTld = (env: Environment) =>
  env === "production"
    ? "https://www.hagerty"
    : `https://${onPremMap[env]}.hagerty`;

const getTld = (country: Country) => (country === "USA" ? "com" : "ca");

const getCountryBaseUrl = (originSansTld: string, country: Country) =>
  `${originSansTld}.${getTld(country)}`;

function getGlobalNavDataApiBaseUrl(env: Environment): string {
  // Locally use public api so we don't have to connect to run spa
  const domain =
    env === "localhost" || env === "production"
      ? "https://api.hagerty.com"
      : `https://${onPremMap[env]}api.hagerty.com`;

  return domain + "/gns/v1";
}

type EnvSubdomain = `.${Exclude<Environment, "production">}` | "";

/**
 * Returns an object that contains functions for requesting all the SPA's URLs.
 * Uses a closure to "close" over the args passed in.
 * This shortens the function signatures below to eliminate the need for
 * importing hooks at each call site to retrieve env and tld info.
 */
export function getUrls(env: Environment, siteTld: TLD): URLs {
  const hagertyOriginSansTld = getHagertyOriginSansTld(env);
  const hagertyOrigin = `${hagertyOriginSansTld}.${siteTld}`;
  const envSubdomain: EnvSubdomain = env === "production" ? "" : `.${env}`;
  const globalNavDataApiBaseUrl = getGlobalNavDataApiBaseUrl(env);

  function getGlobalNavDataApiUrl(
    country: Country,
    location: "header" | "footer"
  ): string {
    const site = country === "CANADA" ? "cadirect" : "usdirect";
    // isFqurl is used to force the API to return fully qualified URLs (static links)
    return `${globalNavDataApiBaseUrl}/${location}/${site}?isFqurl=true`;
  }

  const urls: URLs = {
    home: hagertyOrigin,
    headerDataApi: (country: Country) =>
      getGlobalNavDataApiUrl(country, "header"),
    footerDataApi: (country: Country) =>
      getGlobalNavDataApiUrl(country, "footer"),
    billing: {
      managePayments: `https://payments${envSubdomain}.hagerty.com`,
      payBill: `https://payments${envSubdomain}.hagerty.com`,
    },
    hdc: {
      seeAllPlans: `${hagertyOrigin}/drivers-club/roadside-plans`,
      redeemBenefits:
        siteTld === "com"
          ? `${hagertyOrigin}/drivers-club/benefits`
          : `${hagertyOrigin}/drivers-club`,
      // Yes, this URL is .com only. Per Praneet there is no Upgrade or Cancel link for CA users because they are all HDC 1.0 members.
      // Update. New requirements: https://dev.azure.com/hagerty/Hagerty/_workitems/edit/98523
      upgradeOrCancel: `${hagertyOriginSansTld}.com/drivers-club/my-account`,
      viewMembershipCard: (origin) => `${origin}/membership-card`,
    },
    insurance: {
      addVehicle: (policyNumber, policyCountry, addVehicleFlag) => {
        const base = getCountryBaseUrl(hagertyOriginSansTld, policyCountry);
        return addVehicleFlag === "base"
          ? `${base}/Redirect/PolicyAction/${policyNumber}/addvehicle`
          : `https://account${envSubdomain}.hagerty.com/policies/${policyNumber}/vehicles/add/1?source=${policyCountry}`;
      },

      editVehicle: (policyNumber, policyItemNumber, policyCountry) => {
        const base = getCountryBaseUrl(hagertyOriginSansTld, policyCountry);
        return `${base}/Redirect/PolicyAction/${policyNumber}/editvehicle?policyItemNumber=${policyItemNumber}`;
      },

      // Source is hardcoded because currently self-service does not support removing vehicles in Canada.
      removeVehicle: (
        policyNumber,
        policyItemNumber,
        policyCountry,
        hasTooManyVehiclesOnSinglePolicy
      ) => {
        const base = getCountryBaseUrl(hagertyOriginSansTld, policyCountry);

        if (hasTooManyVehiclesOnSinglePolicy)
          return `${base}/Redirect/PolicyAction/${policyNumber}/RemoveVehicle?policyItemNumber=${policyItemNumber}`;

        return policyCountry === "USA"
          ? `https://account${envSubdomain}.hagerty.com/policies/${policyNumber}/vehicles/${policyItemNumber}/remove?source=usa`
          : `${base}/Redirect/PolicyAction/${policyNumber}/RemoveVehicle?policyItemNumber=${policyItemNumber}`;
      },

      newFileClaim: (country) =>
        country === "USA"
          ? `https://account${envSubdomain}.hagerty.com/claims`
          : "",
      fileClaim: (policyNumber, country) =>
        country === "USA"
          ? `${hagertyOrigin}/Redirect/PolicyAction/${policyNumber}/claims`
          : `${hagertyOriginSansTld}.ca/claim/claim_main.aspx`,

      viewPolicy: (policyNumber, policyCountry, isStateFarm) => {
        if (isStateFarm) {
          return `${getCountryBaseUrl(
            hagertyOriginSansTld,
            policyCountry
          )}/Redirect/PolicyAction/${policyNumber}/viewdocs`;
        }

        return `https://account${envSubdomain}.hagerty.com/documents/${policyNumber}`;
      },

      updatePolicy: (policyCountry) => {
        const base = getCountryBaseUrl(hagertyOriginSansTld, policyCountry);

        return `${base}/PolicyManagement/PolicySummary.aspx`;
      },

      getQuote: (siteCountry) => {
        const suffix =
          siteCountry === "USA" ? "quotemyclassic" : "quotemyclassic-ca";
        return `${hagertyOrigin}/apps/-/${suffix}`;
      },

      // Note: Per Praneet, always use .com for this. The .com URL shows a country selector when clicked.
      connectPolicy: (hasPolicy) =>
        `https://${onPremMap[env]}login.hagerty.com/Connect/Customer?haspolicy=${hasPolicy}`,

      cancelPolicy: (policyCountry) => {
        const base = getCountryBaseUrl(hagertyOriginSansTld, policyCountry);
        return `${base}/PolicyManagement/CancelPolicy.aspx`;
      },

      getCardUrl: (origin, policyCountry, policyNumber) =>
        `${origin}/api/id-card-url/${policyCountry}/${policyNumber}`,

      getDocumentUrl: (origin, policySource, document, policyNumber) =>
        `${origin}/api/document-url/${policySource}/${document}/${policyNumber}`,

      policy: (origin, policyCountry, policyNumber) =>
        `${origin}/api/policy/${policyNumber}?country=${policyCountry}`,

      billingStatements: (policyNumber) =>
        `https://account${envSubdomain}.hagerty.com/documents/${policyNumber}/statements`,

      uploadDocuments: (policyCountry) => {
        const base = getCountryBaseUrl(hagertyOriginSansTld, policyCountry);
        return `${base}/Images2Upload/DocumentUpload.aspx`;
      },
      changeDocumentDelivery: (policyCountry) => {
        const base = getCountryBaseUrl(hagertyOriginSansTld, policyCountry);
        return `${base}/Paperless/DocumentDeliveryOptions.aspx?FromPaperlessBanner=true`;
      },
      editCoverages: (policyCountry) => {
        const base = getCountryBaseUrl(hagertyOriginSansTld, policyCountry);
        return `${base}/PolicyManagement/ChangeCoverages.aspx`;
      },
    },
    account: {
      changeAddress: `${hagertyOrigin}/PolicyManagement/ChangeAddress.aspx`,
      login: `https://${onPremMap[env]}login.hagerty.com`,
      logout: (accountOrigin: string, logoutId: string) =>
        `${accountOrigin}/logout/${logoutId}`,
      editPreferences: (contactId) =>
        `https://cloud.member.hagerty.com/editInfo?SubscriberKey=${contactId}`, // Yes, this is the same everywhere. Thus, this isn't even displayed in non-prod.
      wallet: `https://payments${envSubdomain}.hagerty.com/manage-wallet`,
    },
    corporate: {
      accessibility: `${hagertyOrigin}/corporate/web-accessibility`,
      disclosures: `${hagertyOrigin}/disclosures`,
      privacyPolicy: `${hagertyOrigin}/corporate/privacy-policy`,
      termsOfUse: `${hagertyOrigin}/legal`,
      aboutUs: `${hagertyOrigin}/corporate`,
      marketplace: `${hagertyOriginSansTld}.com/marketplace`,
      agents:
        siteTld === "com"
          ? "https://www.hagertyagent.com"
          : "https://www.hagertybroker.ca",
      contactUs: `${hagertyOrigin}/corporate/contact-us`,
      faq: `${hagertyOrigin}/insurance/frequently-asked-questions`,
      careers: "https://careers.hagerty.com", // NOTE: There isn't a non-prod URL for this, and the link is the same for US and Canada.
      country: {
        usa: "https://www.hagerty.com/",
        canada: "https://www.hagerty.ca/",
        uk: "https://www.hagertyinsurance.co.uk/",
      },
    },
    social: {
      facebook: "https://www.facebook.com/Hagerty",
      instagram: "https://instagram.com/hagerty",
      linkedIn: "https://www.linkedin.com/company/hagertycars",
      youTube: "https://www.youtube.com/hagerty?sub_confirmation=1",
      twitter: "https://twitter.com/Hagerty",
    },
    stateFarm: {
      claim: "https://reportloss.claims.statefarm.com",
    },
  };
  return urls;
}
