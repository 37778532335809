import { Button } from "@hagerty/react-components";
import cx from "clsx";
import styles from "./AccountSettingsRows.module.scss";

type Row = {
  title?: string;
  value: string;
  buttonTitle: string;
  buttonLabel: string;
  hasDivider?: boolean;
  onClick: () => void;
};

export const AccountSettingsRow = (row: Row) => {
  return (
    <div className="my-4">
      {row.title && (
        <div className="grid gutters mb-2">
          <div className={cx("col xs-size_1-of-1 text_4", styles.title)}>
            {row.title}
          </div>
        </div>
      )}
      <div className="grid grid-wrap gutters">
        <div className={"col xs-size_1-of-1 md-size_1-of-2"}>{row.value}</div>
        <div className={"col xs-size_1-of-1 md-size_1-of-2 text-md-right"}>
          <Button
            aria-label={row.buttonLabel}
            buttonType="Secondary"
            onClick={() => row.onClick()}
          >
            {row.buttonTitle}
          </Button>
        </div>
      </div>
      {row.hasDivider && <div className="divider mt-4" />}
    </div>
  );
};
