import styles from "./AppError.module.css";
import { URLs } from "shared";
import { Header } from "../ui/Header";

type AppErrorProps = {
  error: string;
  urls: URLs;
  logoutUrl: string;
};

export function AppError({ error, urls, logoutUrl }: AppErrorProps) {
  return (
    <>
      <Header
        headerDataApiUrl={urls.headerDataApi(
          location.hostname.endsWith(".ca") ? "CANADA" : "USA"
        )}
        hagertyHomeUrl={urls.home}
        logoutUrl={logoutUrl}
      />

      <main className={styles.app}>
        <div className="container container_center">
          <h2>Error loading account profile</h2>
          <p>{error}</p>
          <p>
            Please try again or contact a customer service representative for
            assistance.
          </p>
        </div>
      </main>
    </>
  );
}
