import { ReactComponent as Car } from "./img/car.svg";
import { ReactComponent as Road } from "./img/road.svg";
import { ReactComponent as RightChevron } from "./img/right-chevron.svg";
import { ReactComponent as CirclePlus } from "./img/circle-plus.svg";
import { Link } from "../Link";
import { Button } from "../Button";
import { useUrls } from "../../app/Providers/AppContext";
import { TagAction } from "../../types/Tag.types";

type NoInsurancePresentationProps = {
  titleText: string;
  subTitleText: string;
  connectPolicyText: string;
  footerText: string;
  footerLinkText: string;
  styles: Record<string, string>;
  footerLinkUrl: string;
};

export const NoInsurancePresentation = ({
  titleText,
  subTitleText,
  connectPolicyText,
  footerText,
  footerLinkText,
  footerLinkUrl,
  styles,
}: NoInsurancePresentationProps) => {
  const urls = useUrls();

  const connectPolicyTagAction: TagAction = {
    actionName: connectPolicyText,
    actionType: "link",
  };

  const getQuoteTagAction: TagAction = {
    actionName: footerLinkText,
    actionType: "link",
  };

  return (
    <>
      <div className={styles.titleArea}>
        <h2>{titleText}</h2>
        <p className="text_2">{subTitleText}</p>
      </div>

      <Button
        href={urls.insurance.connectPolicy(false)}
        className={styles.button}
        tagAction={connectPolicyTagAction}
        ariaLabel={connectPolicyText}
      >
        <CirclePlus className="button_primary__icon icon" aria-hidden="true" />
        {connectPolicyText}
      </Button>

      <div className={styles.line} />
      <Road className={styles.road} />
      <Car className={styles.car} />

      <div className={styles.footer}>
        <span>{footerText}</span>
        <Link
          href={footerLinkUrl}
          tagAction={getQuoteTagAction}
          ariaLabel={footerLinkText}
        >
          {footerLinkText}
          <RightChevron />
        </Link>
      </div>
    </>
  );
};
