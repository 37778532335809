import { Button, Error } from "@hagerty/self-service-components";
import { routes } from "../../../routing/routes";

import styles from "./SettingsError.module.scss";

export function SettingsError() {
  return (
    <div className={styles.errorWrapper}>
      <Error>
        <Button as="a" href={routes.settings}>
          Refresh page
        </Button>
      </Error>
    </div>
  );
}
