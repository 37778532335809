import { Dispatch, SetStateAction, ChangeEvent } from "react";
import { Customer, formatPhoneNumber, provincesMap, statesMap } from "shared";
import { P } from "../../../Paragraph";
import { Alert } from "../../../Alert";
import { ValidationErrors } from "../SettingsDialog.types";
import { FormElement, FormInput, FormSelect } from "./form";
import { Link } from "../../../Link";
import { useUrls } from "../../../../app/Providers/AppContext";

import styles from "./SettingsForm.module.scss";

type CustomerKey = keyof Customer;

type SettingsFormProps = {
  isPolicyHolder: boolean;
  customer: Customer;
  setCustomer: Dispatch<SetStateAction<Customer>>;
  validationErrors: ValidationErrors;
  pending: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onBlur: (
    event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  submitted: boolean;
};

export const SettingsForm = ({
  isPolicyHolder,
  customer,
  validationErrors,
  pending,
  onChange,
  onBlur,
  submitted,
}: SettingsFormProps) => {
  const urls = useUrls();
  const {
    firstName,
    middleName,
    lastName,
    line1,
    line2,
    city,
    state,
    postalCode,
    phone,
    country,
  } = customer;

  const isUnitedStates = country === "USA";
  const states = isUnitedStates ? statesMap : provincesMap;

  // Users with policies are only allowed to edit mailing address and phone fields
  // Until the policy call is returned, we disable the non address and phone fields.
  const disableNonAddressAndPhoneField =
    pending || isPolicyHolder === undefined || isPolicyHolder;

  const errorsExist = Object.keys(validationErrors).length > 0;

  return (
    <>
      {submitted && errorsExist && (
        <Alert
          level="danger"
          title="Information is missing or invalid."
          message={<span className={styles.tryAgain}>Please try again.</span>}
          showIcon
        />
      )}

      <P size={2}>
        This is where Hagerty will mail communications and documents.
      </P>

      <FormElement<CustomerKey>
        id="firstName"
        label="First name"
        required
        error={validationErrors.firstName}
      >
        <FormInput
          autoComplete="given-name"
          maxLength={40}
          onChange={onChange}
          onBlur={onBlur}
          value={firstName}
          readOnly={disableNonAddressAndPhoneField}
        />
      </FormElement>

      <FormElement<CustomerKey> id="middleName" label="Middle name">
        <FormInput
          autoComplete="additional-name"
          maxLength={40}
          onChange={onChange}
          value={middleName}
          readOnly={disableNonAddressAndPhoneField}
        />
      </FormElement>

      <FormElement<CustomerKey>
        id="lastName"
        label="Last name"
        required
        error={validationErrors.lastName}
      >
        <FormInput
          autoComplete="family-name"
          maxLength={80}
          onChange={onChange}
          onBlur={onBlur}
          value={lastName}
          readOnly={disableNonAddressAndPhoneField}
        />
      </FormElement>

      <FormElement<CustomerKey> id="line1" label="Street address">
        <FormInput
          autoComplete="address-line1"
          maxLength={255}
          onChange={onChange}
          value={line1}
          readOnly={pending}
        />
      </FormElement>

      <FormElement<CustomerKey> id="line2" label="Street address 2">
        <FormInput
          autoComplete="address-line2"
          maxLength={160}
          onChange={onChange}
          value={line2}
          readOnly={pending}
        />
      </FormElement>

      <FormElement<CustomerKey> id="city" label="City">
        <FormInput
          autoComplete="address-level2"
          maxLength={40}
          onChange={onChange}
          value={city}
          readOnly={pending}
        />
      </FormElement>

      <FormElement<CustomerKey>
        id="state"
        label={isUnitedStates ? "State" : "Province"}
      >
        <FormSelect
          autoComplete="address-level1"
          value={state}
          disabled={pending}
          items={states}
          onChange={onChange}
        />
      </FormElement>

      <FormElement<CustomerKey>
        id="postalCode"
        label={isUnitedStates ? "Zip code" : "Postal code"}
        error={validationErrors.postalCode}
      >
        <FormInput
          autoComplete="postal-code"
          maxLength={20}
          onChange={onChange}
          onBlur={onBlur}
          value={postalCode}
          readOnly={pending}
          placeholder={isUnitedStates ? undefined : "A1A 1A1"}
        />
      </FormElement>

      <FormElement<CustomerKey>
        id="phone"
        label="Phone"
        error={validationErrors.phone}
      >
        <FormInput
          autoComplete="tel-national"
          maxLength={40}
          type="tel"
          title="Format: (123) 456-7890"
          onChange={onChange}
          onBlur={onBlur}
          value={formatPhoneNumber(phone)}
          readOnly={pending}
        />
      </FormElement>

      {/* This only exists so the form can be submitted via the enter key. The visible submit button is part of the dialog settings. */}
      <input type="submit" value="Submit" className={styles.hidden} />

      <div className={styles.disclaimer}>
        <p>
          If you have an insurance policy with Hagerty, you may also need to
          update your insurance address. Because premiums vary from one zip code
          to another, you'll have to do that separately.
        </p>
        <p>
          <Link newTab href={urls.account.changeAddress}>
            View your insurance address
          </Link>
        </p>
      </div>
    </>
  );
};
