import { useEffect } from "react";
import { useFetchApi } from "../../hooks/useFetchApi";
import { useLogHasOutstandingBalance } from "../../app/Providers/LogOnReadyContext";
import { CombinedBilling } from "shared";

export const useBillingPayment = () => {
  const logHasOutstandingBalance = useLogHasOutstandingBalance();
  const { apex, core } = useFetchApi<CombinedBilling>("billing", 2).data;

  // Apex conversation (* 100) is necessary because apex and payments api send the balance in different formats
  const outstandingBalance = apex.outstandingBalance
    ? core.outstandingBalance + apex.outstandingBalance * 100
    : core.outstandingBalance;
  const hasOutstandingBalance = outstandingBalance > 0;

  useEffect(() => {
    logHasOutstandingBalance(hasOutstandingBalance);
  }, [hasOutstandingBalance, logHasOutstandingBalance]);

  return { apex, core };
};
