import { ReactNode } from "react";
import { DialogContent as ReachDialogContent } from "@reach/dialog";
import { LinearProgress } from "./LinearProgress";
import { DialogCloseButton } from "./DialogCloseButton";
import styles from "./DialogContent.module.scss";

export type ShowCloseButton = "no" | "yes" | "disabled";

type DialogContentProps = {
  className?: string;
  label?: string;
  children: ReactNode;
  title?: string;
  footer?: ReactNode;
  description?: string;
  onDismiss?: () => void;
  showCloseButton?: ShowCloseButton;
  showBusy?: boolean;
  ariaBusy?: boolean;
  role?: string;
  closeBtnLabel?: string;
};

export const DialogContent = ({
  title,
  label = title,
  className = "",
  onDismiss,
  children,
  footer,
  showCloseButton = "yes",
  showBusy = false,
  ariaBusy,
  role,
  description = "Beginning of dialog window. Escape will cancel and close the window.",
  closeBtnLabel,
}: DialogContentProps) => {
  const shouldShowCloseButton =
    showCloseButton === "yes" || showCloseButton === "disabled";
  const isCloseButtonDisabled = showCloseButton === "disabled";

  return (
    <ReachDialogContent
      role={role}
      aria-busy={ariaBusy}
      aria-label={label}
      className={`${className} ${styles.container} ${styles.modal__container} modal__container_scrollable`}
    >
      <div className="modal__content">
        <div className="sr-only">{description}</div>

        {title && (
          <div className="modal__header ">
            <h2 className="modal__title">{title}</h2>
          </div>
        )}

        <div className={`modal__body ${styles.modal__body}`}>{children}</div>

        <div className="modal__eyebrow">
          {showBusy && <LinearProgress />}
          {shouldShowCloseButton && (
            <DialogCloseButton
              disabled={isCloseButtonDisabled}
              onClick={onDismiss}
              ariaLabel={closeBtnLabel}
            />
          )}
        </div>

        {footer && (
          <>
            <div className="modal__divider divider" />
            {footer}
          </>
        )}
      </div>
    </ReachDialogContent>
  );
};
