import { Member, MemberAsset, HdcProduct, RoadsideBenefit, VehicleType } from "@hagerty/self-service-components/api/types";
import { DriversClub, HdcVersion, Hdc1Dot5MembershipLevel, Hdc1Dot0MembershipLevel, Benefits } from "./types/DriversClub.types";

export const mapMemberData = (member: Partial<Member>): DriversClub => {
  // Member found but has no membership
  if (!member.assets?.length) {
    return null;
  }

  const membershipAsset: Partial<MemberAsset> = member.assets[0];
  const membershipProduct = membershipAsset?.product;
  const hdcVersion: HdcVersion = membershipAsset?.omniOrderId ? "1.5" : "1.0";

  return {
    membershipId: member.id ?? "",
    hdcVersion: hdcVersion,
    membershipLevel:
      hdcVersion === "1.0"
        ? get1Dot0MembershipLevel(membershipProduct)
        : get1Dot5MembershipLevel(membershipProduct),
    expirationDate: membershipAsset?.expireAt ?? "",
    effectiveDate: membershipAsset?.effectiveAt ?? "",
    benefits: mapRoadsideBenefit(membershipProduct),
  } as DriversClub;
};

const get1Dot5MembershipLevel = (
  product: Partial<HdcProduct> | undefined
): Hdc1Dot5MembershipLevel => {
  return product?.isVIP ? "VIP" : "2";
};

const aaaProductCodes = ["USMEMB0", "USMEMBHDCCBA"];

function get1Dot0MembershipLevel(
  product: Partial<HdcProduct> | undefined
): Hdc1Dot0MembershipLevel {
  if (aaaProductCodes.some((code) => code === product?.code)) {
    return "AAA";
  }

  return product?.code?.endsWith("VIP") ? "VIP" : "2";
}

export const mapRoadsideBenefit = (
  product: Partial<HdcProduct> | undefined
): Benefits | undefined => {
  const benefit = product?.benefits?.find(
    (benefit) => benefit.__typename === "CustomerMemberRoadsideBenefit"
  );

  if (!benefit?.included) return undefined;

  const roadsideBenefit = benefit as RoadsideBenefit;

  return {
    distance: roadsideBenefit.towingDistance ?? 0,
    coversAllVehicles: roadsideBenefit.vehicleType === VehicleType.ALLVEHICLES,
  };
};
