import styles from "./NoInsuranceModal.module.scss";
import { Dialog, useDialog } from "../Dialog";
import { NoInsurancePresentation } from "./NoInsurancePresentation";
import { useEffect } from "react";
import { mt } from "../../utils/tracking";
import { useCountry, useUrls } from "../../app/Providers/AppContext";

export const localStorageKey = "firstTimePolicyPrompt";

export const titleText = "Connect your insurance policy to get started";
export const subTitleText =
  "Make payments, edit your policy, view statements, manage claims, and more.";
export const connectPolicyText = "Connect policy";
export const footerText = "Don't have insurance with us?";
export const footerLinkText = "Get a quote for free";

export const NoInsuranceModal = () => {
  const urls = useUrls();
  const country = useCountry();

  const { close, open, isOpen } = useDialog();

  useEffect(() => {
    const isFirstTime = window.localStorage.getItem(localStorageKey) === null;
    if (isFirstTime) {
      window.localStorage.setItem(localStorageKey, "DO_NOT_SHOW"); // NOTE: any value will do
      mt.trackPopup("Connect policy");
      open();
    }
  }, [open]);

  return isOpen ? (
    <Dialog
      role="dialog"
      onDismiss={() => {
        mt.trackPopupClose();
        close();
      }}
      size="small"
      label="Connect your insurance policy"
    >
      <div className={styles.container}>
        <NoInsurancePresentation
          titleText={titleText}
          subTitleText={subTitleText}
          connectPolicyText={connectPolicyText}
          footerText={footerText}
          footerLinkText={footerLinkText}
          footerLinkUrl={urls.insurance.getQuote(country)}
          styles={styles}
        />
      </div>
    </Dialog>
  ) : null;
};
