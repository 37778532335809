import style from "./Header.module.scss";
import { PrimaryNav } from "@hagerty/global-navigation/dist/cjs/components/PrimaryNav";
import { User } from "@hagerty/global-navigation/dist/cjs/components/PrimaryNav/types";
import { useEffect } from "react";

type HeaderProps = {
  firstName?: string;
  lastName?: string;
  customerId?: string;
  headerDataApiUrl: string;
  hagertyHomeUrl: string;
  logoutUrl: string;
};
export const Header = ({
  firstName = "",
  lastName = "",
  customerId = "",
  hagertyHomeUrl,
  headerDataApiUrl: headerDataApi,
  logoutUrl,
}: HeaderProps) => {
  const user: User = {
    given_name: firstName,
    family_name: lastName,
    sub: customerId,
  };

  // Global nav sets the hagerty logo link to the root of the current site.
  // There's no way to set it in the component so programmatically set it here
  // to the Hagerty home page.
  useEffect(() => {
    document
      .getElementsByClassName("hg-primarynav__link--hagerty")
      .item(0)
      ?.setAttribute("href", hagertyHomeUrl);
  }, []);

  return (
    <>
      <PrimaryNav
        className={style.container}
        dataApi={headerDataApi}
        user={user}
        logoutAction={(e) => {
          e?.preventDefault();
          window.location.assign(logoutUrl);
        }}
      />
    </>
  );
};
