import { GlobalFooter } from "@hagerty/global-navigation/dist/cjs/components/GlobalFooter";

type FooterProps = {
  headerDataApiUrl: string;
};

export function Footer({ headerDataApiUrl }: FooterProps) {
  return (
    <GlobalFooter
      dataApi={headerDataApiUrl}
      isMinimal={true}
      showSitemap={false}
    />
  );
}
